import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170')
];

export const server_loads = [];

export const dictionary = {
		"/": [6],
		"/(authenticated)/admin/(index)": [18,[3]],
		"/(authenticated)/admin/account": [19,[3]],
		"/(authenticated)/admin/admins": [20,[3]],
		"/(authenticated)/admin/admins/create": [21,[3]],
		"/(authenticated)/admin/alumni-historical-programs": [22,[3]],
		"/(authenticated)/admin/alumni-historical-programs/add": [23,[3]],
		"/(authenticated)/admin/alumni-historical-programs/edit/[id]": [24,[3]],
		"/(authenticated)/admin/authentication/sso": [25,[3]],
		"/(authenticated)/admin/broadcast": [26,[3]],
		"/(authenticated)/admin/broadcast/history": [27,[3]],
		"/(authenticated)/admin/campus": [28,[3]],
		"/(authenticated)/admin/campus/create": [31,[3]],
		"/(authenticated)/admin/campus/[id]/delete": [29,[3]],
		"/(authenticated)/admin/campus/[id]/edit": [30,[3]],
		"/(authenticated)/admin/configuration/community-guidelines": [32,[3]],
		"/(authenticated)/admin/configuration/settings": [33,[3]],
		"/(authenticated)/admin/content-moderation": [34,[3]],
		"/(authenticated)/admin/conversations-ambassador": [38,[3]],
		"/(authenticated)/admin/conversations": [35,[3]],
		"/(authenticated)/admin/conversations/conversation/[id]": [36,[3]],
		"/(authenticated)/admin/conversations/new": [37,[3]],
		"/(authenticated)/admin/dataforms-workflows": [45,[3]],
		"/(authenticated)/admin/dataforms-workflows/create": [49,[3]],
		"/(authenticated)/admin/dataforms-workflows/[id]/edit": [46,[3]],
		"/(authenticated)/admin/dataforms-workflows/[id]/preview": [47,[3]],
		"/(authenticated)/admin/dataforms-workflows/[id]/submissions": [48,[3]],
		"/(authenticated)/admin/dataforms": [39,[3]],
		"/(authenticated)/admin/dataforms/create": [44,[3]],
		"/(authenticated)/admin/dataforms/[id]/edit": [40,[3]],
		"/(authenticated)/admin/dataforms/[id]/preview": [41,[3]],
		"/(authenticated)/admin/dataforms/[id]/submissions": [42,[3]],
		"/(authenticated)/admin/dataforms/[id]/submissions/[submissionId]": [43,[3]],
		"/(authenticated)/admin/documents": [50,[3]],
		"/(authenticated)/admin/documents/create": [53,[3]],
		"/(authenticated)/admin/documents/[documentId]/delete": [51,[3]],
		"/(authenticated)/admin/documents/[documentId]/edit": [52,[3]],
		"/(authenticated)/admin/(events-programs)/events": [7,[3]],
		"/(authenticated)/admin/(events-programs)/events/create": [8,[3]],
		"/(authenticated)/admin/(events-programs)/events/edit/[id]": [9,[3]],
		"/(authenticated)/admin/(events-programs)/events/invite/[id]": [10,[3]],
		"/(authenticated)/admin/(events-programs)/events/view/[id]": [11,[3]],
		"/(authenticated)/admin/forum": [54,[3]],
		"/(authenticated)/admin/forum/create-topic": [56,[3]],
		"/(authenticated)/admin/forum/[topicId]": [55,[3]],
		"/(authenticated)/admin/groups": [57,[3]],
		"/(authenticated)/admin/groups/create": [58,[3]],
		"/(authenticated)/admin/groups/edit/[id]": [59,[3]],
		"/(authenticated)/admin/groups/view/[id]": [60,[3]],
		"/(authenticated)/admin/integrations": [61,[3]],
		"/(authenticated)/admin/integrations/sis": [62,[3]],
		"/(authenticated)/admin/integrations/sis/api-keys": [63,[3]],
		"/(authenticated)/admin/integrations/sis/csv-upload": [64,[3]],
		"/(authenticated)/admin/integrations/sis/mappings": [65,[3]],
		"/(authenticated)/admin/interests": [66,[3]],
		"/(authenticated)/admin/interests/create": [67,[3]],
		"/(authenticated)/admin/interests/edit/[id]": [68,[3]],
		"/(authenticated)/admin/interests/merge": [69,[3]],
		"/(authenticated)/admin/interests/replace": [70,[3]],
		"/(authenticated)/admin/majors": [71,[3]],
		"/(authenticated)/admin/majors/create": [72,[3]],
		"/(authenticated)/admin/(events-programs)/programs": [12,[3]],
		"/(authenticated)/admin/(events-programs)/programs/create": [13,[3]],
		"/(authenticated)/admin/(events-programs)/programs/create/[programType]": [14,[3]],
		"/(authenticated)/admin/(events-programs)/programs/edit/[id]": [15,[3]],
		"/(authenticated)/admin/(events-programs)/programs/invite/[id]": [16,[3]],
		"/(authenticated)/admin/(events-programs)/programs/view/[id]": [17,[3]],
		"/(authenticated)/admin/quick-links": [73,[3]],
		"/(authenticated)/admin/quick-links/create": [74,[3]],
		"/(authenticated)/admin/quick-links/edit/[id]": [75,[3]],
		"/(authenticated)/admin/setup": [76,[3]],
		"/(authenticated)/admin/student-organizations": [77,[3]],
		"/(authenticated)/admin/student-organizations/create": [81,[3]],
		"/(authenticated)/admin/student-organizations/import-csv": [82,[3]],
		"/(authenticated)/admin/student-organizations/[id]": [78,[3]],
		"/(authenticated)/admin/student-organizations/[id]/delete": [79,[3]],
		"/(authenticated)/admin/student-organizations/[id]/edit": [80,[3]],
		"/(authenticated)/admin/study-abroad-advisors": [83,[3]],
		"/(authenticated)/admin/super": [84,[3]],
		"/(authenticated)/admin/super/features": [85,[3]],
		"/(authenticated)/admin/tasks": [86,[3]],
		"/(authenticated)/admin/tasks/create": [87,[3]],
		"/(authenticated)/admin/tasks/detail/[id]": [88,[3]],
		"/(authenticated)/admin/tasks/edit/[id]": [89,[3]],
		"/(authenticated)/admin/tasks/report/[id]": [90,[3]],
		"/(authenticated)/admin/tasks/submissions/[id]": [91,[3]],
		"/(authenticated)/admin/tutorials": [92,[3]],
		"/(authenticated)/admin/users": [93,[3]],
		"/(authenticated)/admin/users/allowlist": [94,[3]],
		"/(authenticated)/admin/users/allowlist/edit/[email]": [95,[3]],
		"/(authenticated)/admin/users/allowlist/list": [96,[3]],
		"/(authenticated)/admin/users/detail/[id]": [97,[3]],
		"/(authenticated)/app/alumni": [108,[2]],
		"/(authenticated)/app/ambassadors": [109,[2]],
		"/(authenticated)/app/chat": [110,[2]],
		"/(authenticated)/app/chat/conversation/[id]": [111,[2]],
		"/(authenticated)/app/chat/new": [112,[2]],
		"/(authenticated)/app/classmates": [113,[2]],
		"/(authenticated)/app/classmates/[id]": [114,[2]],
		"/(authenticated)/app/community-guidelines": [115,[2]],
		"/(authenticated)/app/conversation-partners": [116,[2]],
		"/(authenticated)/app/conversation-partners/[id]": [117,[2]],
		"/(authenticated)/app/dataforms": [118,[2]],
		"/(authenticated)/app/dataforms/[id]": [119,[2]],
		"/(authenticated)/app/(events-programs)/events": [98,[2]],
		"/(authenticated)/app/(events-programs)/events/[id]": [99,[2]],
		"/(authenticated)/app/(events-programs)/events/[id]/register": [100,[2]],
		"/(authenticated)/app/(events-programs)/events/[id]/registration": [101,[2]],
		"/(authenticated)/app/(events-programs)/events/[id]/withdraw-registration": [102,[2]],
		"/(authenticated)/app/faculty": [120,[2]],
		"/(authenticated)/app/forum": [121,[2]],
		"/(authenticated)/app/forum/[topicId]": [122,[2]],
		"/(authenticated)/app/forum/[topicId]/create-thread": [124,[2]],
		"/(authenticated)/app/forum/[topicId]/[threadId]": [123,[2]],
		"/(authenticated)/app/friendship-family": [125,[2]],
		"/(authenticated)/app/friendship-family/[id]": [126,[2]],
		"/(authenticated)/app/home": [127,[2]],
		"/(authenticated)/app/me/edit-profile": [128,[2]],
		"/(authenticated)/app/me/notifications": [129,[2]],
		"/(authenticated)/app/me/profile": [130,[2]],
		"/(authenticated)/app/micro-communities": [131,[2]],
		"/(authenticated)/app/micro-communities/[id]": [132,[2]],
		"/(authenticated)/app/my-account": [133,[2]],
		"/(authenticated)/app/my-account/change-password": [134,[2]],
		"/(authenticated)/app/my-account/deletion-request": [135,[2]],
		"/(authenticated)/app/my-account/verify-email": [136,[4]],
		"/(authenticated)/app/(events-programs)/programs": [103,[2]],
		"/(authenticated)/app/(events-programs)/programs/[id]": [104,[2]],
		"/(authenticated)/app/(events-programs)/programs/[id]/register": [105,[2]],
		"/(authenticated)/app/(events-programs)/programs/[id]/registration": [106,[2]],
		"/(authenticated)/app/(events-programs)/programs/[id]/withdraw-registration": [107,[2]],
		"/(authenticated)/app/quick-links": [137,[2]],
		"/(authenticated)/app/staff": [138,[2]],
		"/(authenticated)/app/student-organizations": [139,[2]],
		"/(authenticated)/app/student-organizations/[id]": [140,[2]],
		"/(authenticated)/app/study-abroad-advisors": [141,[2]],
		"/(authenticated)/app/support": [142,[2]],
		"/(authenticated)/app/tasks": [143,[2]],
		"/(authenticated)/app/tasks/submissions": [145,[2]],
		"/(authenticated)/app/tasks/submission/[taskSubmissionId]": [144,[2]],
		"/(authenticated)/app/tasks/task/[taskId]": [146,[2]],
		"/(authenticated)/conversation-partner": [147,[2]],
		"/(authenticated)/conversation-partner/setup-profile": [148,[2]],
		"/(authenticated)/friendship-family": [149,[2]],
		"/(authenticated)/friendship-family/setup-profile": [150,[2]],
		"/(public)/logout": [151],
		"/(public)/public": [152,[5]],
		"/(public)/public/community-guidelines": [153,[5]],
		"/(public)/public/forgot-password": [154,[5]],
		"/(public)/public/forgot-password/success": [155,[5]],
		"/(public)/public/internal/ota-device-id": [156,[5]],
		"/(public)/public/login": [157,[5]],
		"/(public)/public/mobile-app": [158,[5]],
		"/(public)/public/register": [159,[5]],
		"/(public)/public/register/admitted-student": [160,[5]],
		"/(public)/public/register/conversation-partner": [161,[5]],
		"/(public)/public/register/friendship-family": [162,[5]],
		"/(public)/public/register/prospective-student": [163,[5]],
		"/(public)/public/reset-password": [164,[5]],
		"/(public)/public/reset-password/success": [165,[5]],
		"/(public)/public/sso/saml2/campcore": [166,[5]],
		"/(public)/public/sso/saml2/campcore/assert": [167,[5]],
		"/(public)/public/sso/saml2/institution": [168,[5]],
		"/(public)/public/sso/saml2/institution/assert": [169,[5]],
		"/[...rest]": [170]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';